<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >平台课程查询（运营）</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </span>
            <div title="资源提供者" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">资源提供者:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="审核状态" class="searchboxItem ci-full">
              <span class="itemLabel">审核状态:</span>
              <el-select
                v-model="auditState"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in auditStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
                fixed
              />
              <el-table-column
                label="课程名称"
                align="left"
                prop="courseName"
                show-overflow-tooltip
                minWidth="320"
                fixed
              />
              <el-table-column
                label="培训类型"
                align="left"
                prop="trainTypeName"
                show-overflow-tooltip
                width="130"
              />
              <el-table-column
                label="培训等级"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.trainLevelName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="讲师"
                align="left"
                prop="teacherName"
                show-overflow-tooltip
                minWidth="180"
              />
              <el-table-column
                label="总学时"
                align="right"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.lessonNum || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="课件数量"
                align="right"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.kpointNum || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="视频时长"
                align="right"
                show-overflow-tooltip
                minWidth="120"
              >
                <template slot-scope="scope">{{
                  getTime(scope.row.kpointTotalDuration)
                }}</template>
              </el-table-column>
              <el-table-column
                label="课时标准"
                align="right"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.convertClassHour? $setDictionary("CONVERT_CLASS_HOUE", scope.row.convertClassHour) : "45分钟" }}
                </template>
              </el-table-column>
              <el-table-column
                label="总课时"
                align="right"
                show-overflow-tooltip
                minWidth="120"
              >
                <template slot-scope="scope"
                  >{{ scope.row.totalClassHours || 0 }}课时</template
                >
              </el-table-column>
              <el-table-column
                label="可见区域"
                align="left"
                show-overflow-tooltip
                width="100"
                prop="areaName"
              >
                <template slot-scope="scope">{{
                  scope.row.areaName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="课程创建时间"
                align="left"
                show-overflow-tooltip
                minWidth="150"
                prop="createTime"
              >
                <template slot-scope="scope">{{
                  scope.row.createTime | moment
                }}</template>
              </el-table-column>
              <el-table-column
                label="资源提供者"
                align="left"
                show-overflow-tooltip
                width="260"
              >
                <template slot-scope="scope">{{
                  scope.row.compName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="审核状态"
                align="center"
                show-overflow-tooltip
                width="100"
              >
                <template slot-scope="scope">{{
                  $setDictionary("AUDITSTATE", scope.row.auditState) ==
                  "审核未通过"
                    ? "未通过"
                    : $setDictionary("AUDITSTATE", scope.row.auditState) ==
                      "审核通过"
                    ? "已通过"
                    : $setDictionary("AUDITSTATE", scope.row.auditState)
                }}</template>
              </el-table-column>
              <el-table-column
                label="备注"
                align="left"
                prop="remark"
                show-overflow-tooltip
                width="150"
              />
              <el-table-column
                label="操作"
                align="center"
                minWidth="180"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleLook(scope.row.courseId)"
                    >查看详情</el-button
                  >
                 
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="uploadKpointOutline(scope.row)"
                    >下载大纲</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { resetKeepAlive } from "@/utils/common";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "platformCourses",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      courseName: "",
      compId: "",
      auditStateList: [],
      auditState: "30",
   
      CompanyList: [],
     
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
    };
  },
  created() {
    this.getauditStateList();
    this.getQualification();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    /* 查看 */
    handleLook(courseId) {
      this.$router.push({
        path: "/web/detailResource",
        query: {
          courseId,
          stu: "query",
        },
      });
    },
    getauditStateList() {
      const auditStatelist = this.$setDictionary("AUDITSTATE", "list");
      const list = [{ label: "全部", value: "" }];
      for (const key in auditStatelist) {
        if (auditStatelist[key] == "审核通过") {
          list.push({
            value: key,
            label: "已通过",
          });
        } else if (auditStatelist[key] == "审核未通过") {
          list.push({
            value: key,
            label: "未通过",
          });
        } else {
          list.push({
            value: key,
            label: auditStatelist[key],
          });
        }
      }
      this.auditStateList = list;
    },
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        salesState: '20'
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.auditState) {
        params.auditState = this.auditState;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      this.doFetch({
        url: "/platform/resources/course/pageList",
        params,
        pageNum,
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
  
    /* 下载大纲 */
    uploadKpointOutline(row) {
        this.$post("/platform/resources/course/asyncDownloadCourseOutline", { courseId:row.courseId,courseName:row.courseName  })
        .then((res) => {
          if (res.status == "0") {
          let list = res.data;
          if (!this.downloadItems.includes(list.taskId)) {
            this.$store.dispatch("pushDownloadItems", list.taskId);
          } else {
            this.$message.warning(
              "[" + list.fileName + "]已经申请下载,请耐心等待"
            );
          }
        } else {
          this.$message.error(res.message);
        }
        })
        .catch((err) => {
          return;
        });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<style lang="less">
.seeArea {
  .el-radio-group {
    display: flex;
    flex-direction: column;
    .el-radio :last-child {
      margin-top: 1rem;
    }
  }
}

.searchbox {
  padding: 0;
}
.cascader {
  position: relative;
  width: 100%;
  z-index: 10;
}
.tp {
  width: 50%;
  float: left;
  .img-el-upload {
    width: 63%;
    float: left;
    margin-right: 2%;
  }
  .tswz {
    float: left;
    font-size: 12px;
    line-height: 25px;
    color: #909399;
    .t1 {
      font-size: 14px;
      line-height: 40px;
      color: #606266;
    }
  }
}
.form-box {
  padding: 10px 20px;
  .form {
    min-width: 600px;
    padding: 20px;
  }
  .form-bg {
    background-color: rgb(247, 248, 253);
  }
}
</style>
<style lang="less" scoped>
.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
</style>
